import { instance } from "./instances/coindisco";

interface RegionDTO {
  id: number;
  kind: string;
  name: string;
  letterId: string;
  alpha2Code: string;
  alpha3Code: string;
  icon: string;
  parentRegion: RegionDTO | null;
  default: boolean;
  childExists: boolean;
}

interface RegionWithStatesDTO extends RegionDTO {
  states: RegionDTO[];
  parentRegion: null;
}

const servicesAPI = new (class {
  public async fetchAllRegions() {
    const { data } = await instance.get<RegionWithStatesDTO[]>(
      "service-integration/regions/all/",
    );

    return data;
  }

  public async fetchPaymentMethodsForRegion(regionId: number) {
    const { data } = await instance.get<PaymentOption[]>(
      `service-integration/regions/${regionId}/payment-methods/`,
    );

    return data;
  }

  public async fetchCurrenciesForRegion(regionId: number) {
    const { data } = await instance.get<CurrencyWithDefault[]>(
      `service-integration/regions/${regionId}/currencies/`,
    );

    return data;
  }

  public async fetchRegion(regionLetterId: Region["letterId"] | void) {
    const { data } = await instance.post<RegionWithStatesDTO>(
      "service-integration/regions/my/",
      {
        regionLetterId,
      },
    );

    return data;
  }

  public async fetchProviders() {
    const { data } = await instance.get<Provider[]>(
      "service-integration/active-providers/",
    );

    return data;
  }
})();

export default servicesAPI;
